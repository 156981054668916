import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import {MOBI,WEB} from './images'
const App2 = lazy(() => import('./components/home/App2'));
const App = lazy(() => import('./components/home/App'));
const Preloader = import('./preloader');
const MyLazyComp = React.lazy(() => Preloader);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Component = () => {
  const { width } = useWindowDimensions();
  const [online, setOnline] = useState(null)
  const [lang, setLang] = useState('zh')
  const [locale, setLocale] = useState(undefined)
  const [scroll, setScroll] = useState(false)

  var timer = null;
  window.addEventListener('scroll', function() {
      if(timer !== null) {
        setScroll(true)
        clearTimeout(timer);        
      }
      timer = setTimeout(function() {
        setScroll(false)
      }, 500);
  }, false);
  
  function getTime(){
    var time = Math.floor(new Date().getTime() / 1000);
    if(time > 1671510422) setOnline(false)
    else setOnline(false)
  }

  useEffect(() => {
    getTime()
  },[width])

  useEffect(() => {
    const test = async () => {
      const resp = await fetch(`./lang/${lang}.json`)
      const data = await resp.json()
      setLocale(data)
    }
    test();
  },[lang])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <>
        <MyLazyComp img={width<810 ? MOBI : WEB}/>
        {
          width < 810 ?
          <App2 setLang={setLang} setting={{locale:locale,lang:lang,online:online,scroll:scroll}}/>
          :
          <App setLang={setLang} setting={{locale:locale,lang:lang,online:online,scroll:scroll}}/>
        }
      </>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Component />
);
