export const WEB = [
    {
      id: 0,
      url: "images/web/BG_behind.png"
    },
    {
      id: 0,
      url: "images/web/BG_forward.png"
    },
    {
      id: 0,
      url: "images/web/BG_screen_01.png"
    },
    {
      id: 0,
      url: "images/web/Beethoven.png"
    },
    {
      id: 0,
      url: "images/web/BlueMochi_Beethoven.png"
    },
    {
      id: 0,
      url: "images/web/BlueMochi_einstein.png"
    },
    {
      id: 0,
      url: "images/web/bulb.png"
    },
    {
      id: 0,
      url: "images/web/einstein.png"
    },
    {
      id: 0,
      url: "images/web/host.png"
    },
    {
      id: 0,
      url: "images/web/Napoleon.png"
    },
    {
      id: 0,
      url: "images/web/LOGO_v1.png"
    },
    {
      id: 1,
      url: "images/web/intro_01.png"
    },
    {
      id: 2,
      url: "images/web/intro_BG.png"
    },
    {
      id: 2,
      url: "images/web/BG_02.png"
    },
]
  
export const MOBI = [
    {
      id: 0,
      url: "images/mobi/BG_behind.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_forward.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_screen_01.png"
    },
    {
      id: 0,
      url: "images/mobi/BlueMochi_Beethoven.png"
    },
    {
      id: 0,
      url: "images/mobi/BlueMochi_einstein.png"
    },
    {
      id: 0,
      url: "images/mobi/bulb.png"
    },
    {
      id: 0,
      url: "images/mobi/einstein.png"
    },
    {
      id: 0,
      url: "images/mobi/host.png"
    },
    {
      id: 0,
      url: "images/mobi/Napoleon.png"
    },
    {
      id: 0,
      url: "images/mobi/LOGO_v1.png"
    },
    {
      id: 1,
      url:
        "images/mobi/intro_01.png"
    },
    {
      id: 2,
      url:
        "images/mobi/intro_BG.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_02_mobi.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_03_mobi.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_04_mobi.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_05_mobi.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_06_mobi.png"
    },
    {
      id: 0,
      url: "images/mobi/BG_07_mobi.png"
    },
]
  